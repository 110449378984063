<template lang="pug">
div.d-flex
  v-autocomplete(label='CBR account'
    ref="cbr"
    :error="formErrors.hasError('accountUsername')"
    :error-messages="formErrors.fields.accountUsername"
    :value="value"
    @input="onInputChange"
    :items="items"
    data-test="candidate-cbr-user"
    item-text="username"
    item-value="ID"
  ) 
    template(v-slot:item="{ item }")
      v-list-item-content(:class="{'current-account': item.current}") {{ item.username }}
    
  v-checkbox(
    v-show="hasDefaultProduct"
    data-test="candidate-cbr-user-default"
    :label="defaultCBRUser.username" 
    v-if="defaultCBRUser" hide-details v-model="defaultCBR" @change="setDefaultCBRUser").candidate-cbr-user-default
</template>

<script>
import {mapGetters, mapActions} from "vuex"
export default {
  props: {
    value: {
      required: true
    },
    formErrors: {
      type: Object,
      required: true
    },
    formattedUsersList: {
      type: Array,
      default: () => []
    },
    product: Object
  },

  data: () => ({
    defaultCBR: false,
  }),

  computed: {
    ...mapGetters({
      cbrUsers: 'cbrUser/items',
      defaultCBRUser: 'cbrUser/default'
    }),

    currentLocationUsers() {
      let location = this.itemModel.course.city
      if(!location) return []
      return this.cbrUsers.filter(item => item.courseLocations.includes(location))
    },

    items() {
      if(!this.cbrUsers) return []
      
      let cbrUsers = this.cbrUsers.map(user => {
        user.current = false
        return user
      })
      let currentUsers = this.formattedUsersList.map(user => {
        user.current = true
        return user
      })
      let users = [...currentUsers, ...cbrUsers]
      if(this.product) {
        users = users
          .filter(user =>  
            user.products
              .some(product => product === this.product.name)
          )
        if(!this.hasUserIn(users)) this.onInputChange(null)
      }
      return users
    },

    hasDefaultProduct() {
      if(this.product && this.items.some(item => item.username === this.defaultCBRUser.username)) return true
      return false
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    ...mapActions({
      loadList: 'cbrUser/list'
    }),

    async loadData() {
      await Promise.all([this.loadList({cache: true})])
    },

    onInputChange(val) {
      this.$emit('input', val)
    },
    setDefaultCBRUser() {
      if(!this.defaultCBR) {
        this.onInputChange(null)
        return
      }
      this.onInputChange(this.defaultCBRUser.ID)
    },

    hasUserIn(items) {
      let item = items.find(_item => _item.ID === this.value)
      return item ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.current-account {
  font-weight: bold;
}
</style>